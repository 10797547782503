import {
  UpdateDocumentBody,
  UpdateDocumentBodyDocument,
  Document
} from "@syadem/kairos-citizen-js";
import { Service } from "../service";
import { ActionResult } from "../../actionResult";
import { Apis } from "../../network/apis";

interface Payload {
  document: {
    name?: UpdateDocumentBodyDocument["name"];
    category?: UpdateDocumentBodyDocument["category"];
    date?: UpdateDocumentBodyDocument["date"];
  };
  healthRecordId: string;
  documentId: string;
}

export class UpdateDocumentService implements Service<Payload, Document> {
  constructor(
    private readonly apis: Apis,
  ) { }

  async call({
    document,
    healthRecordId,
    documentId,
  }: Payload): Promise<ActionResult<Document>> {
    const updateDocumentBody: UpdateDocumentBody = {
      document
    };

    const apiDocument = (await this.apis.documentApi.updateDocument(healthRecordId, documentId, updateDocumentBody));

    return {
      type: "success",
      value: apiDocument,
    };
  }
}
