import { Service } from "../service";
import { ActionResult } from "../../actionResult";
import { Apis } from "../../network/apis";

interface Payload {
  healthRecordId: string;
  documentId: string;
  attachment: {
    fileId: string;
    filename: string;
  };
}

export class DownloadDocumentFileService implements Service<Payload, Blob> {
  constructor(
    private readonly apis: Apis,
  ) { }

  async call({ healthRecordId, documentId, attachment }: Payload): Promise<ActionResult<Blob>> {
    const blob = await this.apis.documentFileApi.downloadFile({ healthRecordId, documentId, attachment });

    return {
      type: "success",
      value: blob,
    };
  }
}
