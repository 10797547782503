import dayjsLib, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { LocaleString } from "../translations";
import { I18nInterface } from "../ui/providers/I18nContext";

dayjsLib.extend(customParseFormat);
dayjsLib.extend(utc);
dayjsLib.extend(localizedFormat);
dayjsLib.extend(localeData);
dayjsLib.extend(relativeTime);
export const dayjs = dayjsLib;

export const formatDistanceToNow = (locale: string, date: Date) => {
  return dayjs(date)
    .locale(locale == "fr" ? "fr" : "en")
    .fromNow(true);
};

export const localizedDate = (date: string | Date | Dayjs, locale: LocaleString) => {
  return dayjs(date).locale(locale).format("L");
};

export const localizedPlaceholder = (locale: LocaleString, t: I18nInterface["t"]): string => {
  // @ts-ignore
  const format = dayjs().locale(locale).$locale().formats.L;
  return t(`common.dates.${format}`);
}