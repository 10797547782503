import { Service } from "../service";
import { Apis } from "../../network/apis";
import { ActionResult } from "../../actionResult";

interface Payload {
  healthRecordId: string;
  documentId: string;
}

export class DeleteDocumentService implements Service<Payload, void> {
  constructor(
    private readonly apis: Apis
  ) { }

  async call({ healthRecordId, documentId }: Payload): Promise<ActionResult<void>> {
    await this.apis.documentApi.deleteDocument(healthRecordId, documentId);

    return {
      type: "success",
      value: undefined,
    };
  }
}
