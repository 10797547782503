import { Document } from "@syadem/kairos-citizen-js/dist/generated/models/Document";
import { useCallback, useEffect, useRef, useState } from "react";
import { useKairosApi } from "./useKairosApi";

export function useHealthRecordDocuments(id: string): {
  isLoading: boolean;
  documents?: Document[];
  error?: Response | Error;
  refreshDocuments: () => void;
} {
  const api = useKairosApi();

  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const fetchingRef = useRef(false);

  const fetchDocuments = useCallback(async () => {
    if (!api?.documentApi) return;

    fetchingRef.current = true
    setIsLoading(true);
    setError(undefined);
    try {
      const { documents } = await api.documentApi.indexDocuments(id);
      setDocuments(documents);
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        setError(error);
      } else {
        throw error;
      }
    } finally {
      fetchingRef.current = false;
      setIsLoading(false);
    }
  }, [api, id]);

  useEffect(() => {
    if (fetchingRef.current) return;
    fetchDocuments();
  }, [fetchDocuments]);

  return { isLoading, documents, error, refreshDocuments: fetchDocuments };
} 