import { alpha, FormControl, FormHelperText, InputBase, InputBaseProps, InputLabel, styled } from "@mui/material";
import { theme } from "../../layout/Theme";

interface StyledInputProps extends InputBaseProps {
  label?: string;
  errorMessage?: string;
}

export const LightBootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.Mui-error": {
    ".MuiInputBase-input": {
      borderColor: theme.palette.error[600],
      background: theme.palette.error[100],
      color: theme.palette.error[400],
      borderRadius: 4,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.error[600] as string, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error[600],
        borderRadius: 4,
      },
      ".MuiTypography-root": {
        color: theme.palette.error[400],
      },
    },
    svg: {
      color: theme.palette.error[400],
    },
  },
  "input[readonly]": {
    boxShadow: "none",
    borderColor: theme.palette.neutral[200],
    cursor: "default",
    color: theme.palette.neutral[600],
    background: "none",
  },
  "& .MuiInputBase-input": {
    height: "22px",
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: theme.palette.neutral[200],
    fontSize: 14,
    padding: "8px 16px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      borderColor: theme.palette.neutral[500],
    },
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
    },
    width: "100%",
  },
}));

export const LightStyledInput = ({
  label,
  name,
  fullWidth,
  error,
  disabled,
  value,
  required,
  placeholder,
  readOnly,
  onChange,
  errorMessage,
  ...rest
}: StyledInputProps) => {
  return (
    <FormControl
      error={!readOnly && error}
      fullWidth={!!fullWidth}
      disabled={disabled}
      required={required}
    >
      {label ? (
        <InputLabel
          shrink
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            },
            "&.Mui-focused": {
              color: readOnly ? theme.palette.neutral[600] : theme.palette.primary.main,
            }
          }}
        >
          {label}
        </InputLabel>
      ) : null}
      <LightBootstrapInput
        {...rest}
        value={value}
        name={name}
        size="small"
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
      {!readOnly && error && errorMessage ? (
        <FormHelperText sx={{ ml: 0 }}>{errorMessage}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
