import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { I18nProvider } from "../src/ui/providers/I18nProvider";
import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import { theme } from "../src/ui/layout/Theme";
import { routes } from "../src/ui/routes";
import { UserManager } from "oidc-client-ts";
import { Apis, initApis } from "../src/network/apis";
import { AppStore, initStore } from "../src/store";
import { DependenciesProvider } from "../src/ui/providers/DependenciesProvider";
import { Loading } from "../src/ui/components/shared/Loading";
import { initServices, Services } from "../src/services";
import { LoadingAppFailed } from "../src/ui/pages/LoadingAppFailed";
import { ResultNotifier } from "../src/resultNotifier";
import { ServiceBus } from "../src/services/serviceBus";
import { initQueries, Queries } from "../src/queries";
import { Daphne, initDaphne } from "@syadem/daphne-js";
import { validateSecurityNumber } from "./utils/securityNumberValidator";
import { SecurityNumberInput } from "./components/SecurityNumberInput";
import { AppRouter, Config, CountryConfig } from "../src/appConfig";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";

export class App {
  private readonly store: AppStore;
  private readonly apis: Apis;
  private readonly services: Services;
  private readonly queries: Queries;
  private readonly router: AppRouter;
  private readonly serviceBus: ServiceBus;
  private readonly resultNotifier: ResultNotifier;
  private readonly countryConfig: CountryConfig;
  private daphne?: Daphne;

  constructor(private readonly config: Config) {
    const { oidcUrl, kairosCitizenApiBasePath, kairosCertApiBasePath, sadApiBasePath, arianeApiBasePath } = config;
    const oidcManager = initOidcManager(oidcUrl);
    const currentToken = async () => (await oidcManager.getUser())?.access_token ?? "";
    this.store = initStore();
    
    this.apis = initApis({
      kairosCitizenApiBasePath,
      kairosCertApiBasePath,
      sadApiBasePath,
      arianeApiBasePath,
      accessToken: currentToken
    });

    this.router = createBrowserRouter(routes);
    this.resultNotifier = new ResultNotifier(this.store);

    this.countryConfig = {
      defaultLocale: "fr",
      availableLocales: ["fr", "en-gb"],
      defaultCountryCode: "FRA",
      zipCode: {
        length: 5,
        regex: /^(?:0[1-9]|[1-9]\d|9[0-8])\d{3}$/,
        placeholder: "ex : 33000",
        sharingPlaceholder: "ex : Bordeaux, 33000, ...",
        requiredForHealthRecord: true
      },
      securityNumber: {
        validator: validateSecurityNumber,
        Input: SecurityNumberInput
      }
    }

    this.queries = initQueries({ apis: this.apis });

    this.services = initServices({
      store: this.store,
      oidcManager,
      router: this.router,
      apis: this.apis,
    });

    this.serviceBus = new ServiceBus(this.services, this.resultNotifier, this.router);
  }

  async start() {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    
    root.render(
      <React.StrictMode>
        <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
          <Box sx={{ width: "100vw", height: "100vh" }}>
            <Loading title={"Authentification en cours..."} />
          </Box>
        </I18nProvider>
      </React.StrictMode>
    );

    try {
      const [daphne] = await Promise.all([
        initDaphne({ baseUrl: this.config.nuvaUrl, locale: this.countryConfig.defaultLocale }),
        this.services.signIn.call()]
      );
      this.daphne = daphne;
    } catch {
      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>
      );
      return;
    }

    const authState = this.store.getState().authState;

    if (authState.type != "signed-in") {
      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>
      );
      return;
    }

    root.unmount();

    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <React.StrictMode>
        <DependenciesProvider
          dependencies={{
            store: this.store,
            serviceBus: this.serviceBus,
            queries: this.queries,
            arianeApi: this.apis.arianeApi,
            sadApi: this.apis.sadApi,
            daphne: this.daphne,
            apis: this.apis,
            countryConfig: this.countryConfig
          }}
        >
          <ThemeProvider theme={theme}>
            <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
              <RouterProvider router={this.router} />
            </I18nProvider>
          </ThemeProvider>
        </DependenciesProvider>
      </React.StrictMode>
    );
  }
}

const OIDC_CLIENT = "kairos-citizen-front";

function initOidcManager(oidcUrl: string) {
  const oidcManager = new UserManager({
    client_id: OIDC_CLIENT,
    authority: oidcUrl,
    redirect_uri: window.location.href,
    loadUserInfo: true,
  });
  oidcManager.events.addAccessTokenExpired((error: unknown) => {
    console.debug("Access token expired error", error);
  });
  oidcManager.events.addUserSignedOut(() => {
    alert("User signed out");
  });
  oidcManager.events.addSilentRenewError((error: unknown) => {
    console.debug("Silent renew error", error);
    // reload window to force user to sign in again
    window.location.reload();
  });
  return oidcManager;
}