import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, Box, Button, ListItem, ListItemAvatar, ListItemText, Tooltip, useMediaQuery } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { HealthRecord, VaccinationAct, VaccinationActCertificationMethodEnum } from "@syadem/kairos-citizen-js";
import { Link } from "react-router-dom";
import { calculateAge } from "../../utils/ageCalculator";
import { certificationDescription } from "../../utils/certificationDescription";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { theme } from "../layout/Theme";
import { Vaccine } from "./Vaccine";
import { ListItemLink } from "./shared/ListItemLink";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { localizedDate } from "../../utils/dayjs";

export function isCertified(certificationMethod: VaccinationActCertificationMethodEnum) {
  return (
    certificationMethod === VaccinationActCertificationMethodEnum.Execution ||
    certificationMethod === VaccinationActCertificationMethodEnum.Proof
  );
}

interface VaccinationActListItemProps {
  vaccinationAct: VaccinationAct;
  healthRecord: HealthRecord;
  showActions: boolean;
  refreshCallback?: () => void;
  daphne: Daphne;
  certificator: Professional | Team | undefined;
  displayDiseases?: boolean;
}

export function VaccinationActListItem(props: VaccinationActListItemProps) {
  const {
    vaccinationAct,
    healthRecord,
    showActions,
    refreshCallback,
    daphne,
    certificator,
    displayDiseases = true,
  } = props;
  const { t, locale } = useI18n();
  const api = useKairosApi();
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <ListItem
      secondaryAction={
        showActions &&
        refreshCallback &&
        api && (
          <Box display="flex" sx={{ mr: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={`vaccination/${vaccinationAct.id}`}
              startIcon={
                isCertified(vaccinationAct.certificationMethod) ? (
                  <VisibilityIcon color="primary" />
                ) : (
                  <EditOutlinedIcon color="primary" />
                )
              }
              disableElevation
              sx={{ ml: 1, width: { xs: "auto", md: "110px" } }}
              size={breakpointMdUp ? "medium" : "small"}
            >
              {isCertified(vaccinationAct.certificationMethod) ? t("common.cta.view") : t("common.cta.edit")}
            </Button>
          </Box>
        )
      }
      sx={{
        mb: 1,
        p: 0,
        ".MuiListItemSecondaryAction-root": {
          right: { xs: 0, md: 16 },
          bottom: { xs: 8, md: 16 },
          top: { xs: "unset", md: "43%" },
          transform: { xs: "unset", md: "translateY(-50%)" },
        },
      }}
      className={isCertified(vaccinationAct.certificationMethod) ? "vaccinationAct" : "vaccinationActNotCertified"}
    >
      <ListItemLink
        to={`vaccination/${vaccinationAct.id}`}
        sx={{
          px: { xs: 1, md: 3 },
          pt: 1,
          pb: { xs: showActions ? 6 : 1, md: 1 },
          border: `solid 1px ${theme.palette.neutral[200]}`,
          borderRadius: 1,
          bgcolor: "background.paper",
          "&:hover": {
            border: `solid 1px ${theme.palette.primary[500]}`,
            bgcolor: "background.paper",
          },
        }}
        data-testid="health-record"
      >
        <ListItemAvatar>
          <Tooltip
            title={
              isCertified(vaccinationAct.certificationMethod) && certificator
                ? certificationDescription(certificator, vaccinationAct, t, locale)
                : t("vaccines.not_validated")
            }
            arrow
          >
            <Avatar
              sx={{
                background:
                  isCertified(vaccinationAct.certificationMethod) && certificator
                    ? theme.palette.success[100]
                    : theme.palette.neutral[100],
                width: { xs: 40, sm: 48 },
                height: { xs: 40, sm: 48 },
                mr: 2,
              }}
            >
              <TaskAltOutlinedIcon
                sx={{
                  color:
                    isCertified(vaccinationAct.certificationMethod) && certificator
                      ? theme.palette.success[500]
                      : theme.palette.neutral[500],
                }}
              />
            </Avatar>
          </Tooltip>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Vaccine
              vaccineId={vaccinationAct.vaccineId}
              vaccinationAct={vaccinationAct}
              daphne={daphne}
              certificator={certificator}
              displayDiseases={displayDiseases}
            />
          }
          secondary={t("vaccines.administration", {
            date: localizedDate(vaccinationAct.performedOn, locale),
            age: calculateAge(new Date(vaccinationAct?.performedOn), new Date(healthRecord?.birthDate), true, t),
          })}
        />
      </ListItemLink>
    </ListItem>
  );
}
