import { Box, Button, LinearProgress, Paper, Typography, Stack, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { EmptyListPlaceholder } from "../../../components/shared/EmptyListPlaceholder";
import DocumentList from "./DocumentList";
import StyledDialog from "../../../components/mui/StyledDialog";
import { useCallback, useState } from "react";
import { Document } from "@syadem/kairos-citizen-js";
import { useServiceBus } from "../../../hooks/useServiceBus";
import { DocumentForm } from "./DocumentForm";
import { DeleteButton } from "../../../components/mui/StyledButtons";
import { useHealthRecordDocuments } from "../../../hooks/useHealthRecordDocuments";

function getMimeTypeFromFilename(filename: string): string {
  const extension = filename.toLowerCase().split(".").pop() || "";
  const mimeTypes: Record<string, string> = {
    pdf: "application/pdf",
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    tiff: "image/tiff",
  };

  return mimeTypes[extension] || "application/octet-stream";
}

export function Documents() {
  const { t } = useI18n();
  const { id } = useParams() as { id: string };
  const serviceBus = useServiceBus();

  const { documents, isLoading, error: documentsError, refreshDocuments } = useHealthRecordDocuments(id);

  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
  const [editingDocument, setEditingDocument] = useState<Document | undefined>();
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDownload = async (documentId: string, attachment: { fileId: string; filename: string }) => {
    try {
      const result = await serviceBus.dispatch({
        type: "downloadDocumentFile",
        healthRecordId: id,
        documentId: documentId,
        attachment: attachment,
      });
      if (result.type === "error") {
        throw new Error(result.message);
      }
      const url = window.URL.createObjectURL(result.value);
      const a = document.createElement("a");
      a.href = url;
      a.download = attachment.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDeleteRequest = (documentId: string) => {
    setDocumentToDelete(documentId);
  };

  const confirmDelete = useCallback(async () => {
    if (!documentToDelete) return;
    try {
      setIsDeleting(true)
      await serviceBus.dispatch({
        type: "deleteDocument",
        healthRecordId: id,
        documentId: documentToDelete,
      });
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setIsDeleting(false)
      setDocumentToDelete(null);
      refreshDocuments()
    }
  }, [documentToDelete, id, serviceBus, refreshDocuments]);

  const handlePreview = async (documentId: string, attachment: { fileId: string; filename: string }) => {
    try {
      const result = await serviceBus.dispatch({
        type: "downloadDocumentFile",
        healthRecordId: id,
        documentId: documentId,
        attachment: attachment,
      });
      if (result.type === "error") {
        throw new Error(result.message);
      }
      const mimeType = getMimeTypeFromFilename(attachment.filename);
      const blobUrl = URL.createObjectURL(new Blob([result.value], { type: mimeType }));
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error previewing file:", error);
    }
  };

  const handleCloseAddDocument = useCallback(() => {
    setIsAddDocumentOpen(false);
    refreshDocuments()
  }, [setIsAddDocumentOpen, refreshDocuments]);

  const handleCloseEditDocument = useCallback(() => {
    setEditingDocument(undefined);
    refreshDocuments()
  }, [setEditingDocument, refreshDocuments]);

  return (
    <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
      {documentsError && (
        <Typography sx={{ my: 4 }} align="center" component="div">
          <Alert severity="error">{t("common.errors.default")}</Alert>
        </Typography>
      )}

      {isLoading && <LinearProgress variant="query" sx={{ my: 2 }} />}

      {!isLoading && documents && (
        <>
          {!documents || documents.length === 0 ? (
            <EmptyListPlaceholder
              icon={<ArticleIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
              title={t("documents.no_documents")}
              button={
                <Button
                  size="large"
                  onClick={() => setIsAddDocumentOpen(true)}
                  variant="contained"
                  startIcon={<AddIcon />}
                  disableElevation
                >
                  {t("documents.cta.add")}
                </Button>
              }
            >
              {t("documents.cta.add_msg")}
            </EmptyListPlaceholder>
          ) : (
            <Box sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">{t("documents.title")}</Typography>
                <Button
                  onClick={() => setIsAddDocumentOpen(true)}
                  variant="contained"
                  startIcon={<AddIcon />}
                  disableElevation
                >
                  {t("documents.cta.add")}
                </Button>
              </Stack>

              <DocumentList
                documents={documents}
                onDownload={handleDownload}
                onDelete={handleDeleteRequest}
                onPreview={handlePreview}
                onEdit={setEditingDocument}
              />
            </Box>
          )}
          <StyledDialog
            open={isAddDocumentOpen}
            onClose={handleCloseAddDocument}
            title={t("documents.add_document")}
            hideDialogActions
          >
            <DocumentForm onClose={handleCloseAddDocument} documents={documents} />
          </StyledDialog>
          <StyledDialog
            open={!!editingDocument}
            onClose={handleCloseEditDocument}
            title={t("documents.edit_document")}
            hideDialogActions
          >
            <DocumentForm onClose={handleCloseEditDocument} existingDocument={editingDocument} hideFileUpload documents={documents} />
          </StyledDialog>
          <StyledDialog
            open={documentToDelete !== null}
            onClose={() => setDocumentToDelete(null)}
            title={t('documents.delete_document')}
            dialogActions={
              <DeleteButton
                onClick={confirmDelete}
                autoFocus
                startIcon={<DeleteOutlineIcon />}
                loading={isDeleting}
              >
                {t("common.cta.delete")}
              </DeleteButton>
            }
          >
            {t("documents.delete_confirmation_message")}
          </StyledDialog>
        </>
      )}
    </Paper>
  );
}
