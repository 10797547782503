import { Box, FormControl, FormHelperText, InputLabel, alpha } from "@mui/material";
import { dayjs } from "../../../utils/dayjs";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { LightStyledInput } from "./LightStyledInput";

interface StyledDatePickerProps extends DatePickerProps<Dayjs> {
  label?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  open?: boolean;
  fullWidth?: boolean;
}

const LightStyledDatepicker = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  errorMessage,
  minDate,
  maxDate,
  disabled,
  readOnly,
  fullWidth,
  required,
  open,
  onOpen,
  onClose
}: StyledDatePickerProps) => {
  const { locale } = useI18n();

  return !readOnly ? (
    <FormControl
      fullWidth={!!fullWidth}
      error={error}
      disabled={disabled}
      required={required}
    >
      {label ? (
        <InputLabel
          shrink
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "visible",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            }
          }}
        >
          {label}
        </InputLabel>
      ) : null}
      <Box
        sx={{
          marginTop: 3,
          ".MuiFormControl-root": {
            width: fullWidth ? "100%" : "auto",
            "&.Mui-error": {
              borderColor: theme.palette.error[600],
              background: theme.palette.error[100],
              color: theme.palette.error[400],

              ".MuiInputBase-root.MuiOutlinedInput-root": {
                color: theme.palette.error[400],
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  boxShadow: `${alpha(theme.palette.error[600] as string, 0.25)} 0 0 0 0.2rem`,
                  borderColor: theme.palette.error[600],
                  borderRadius: "4px",
                },
                svg: {
                  color: theme.palette.error[400],
                },
              },
            },
          },
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            borderRadius: "4px",
            fontSize: "14px",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.neutral[200]
            }
          },
          ".MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            borderColor: theme.palette.neutral[500]
          },
          ".MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            borderWidth: "1px",
          },
          ".MuiInputBase-input.MuiOutlinedInput-input": {
            paddingY: "10px",
            paddingLeft: "16px",
          }
        }}
      >
        <DatePicker
          className={error ? "Mui-error" : ""}
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            actionBar: {
              actions: ["clear", "today"],
            },
            textField: {
              error: Boolean(error),
              required: required,
              inputProps: {
                name: name,
                placeholder: placeholder
              }
            }
          }}
          disabled={disabled}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
      {error && errorMessage ? (
        <FormHelperText sx={{ ml: 0 }}>{errorMessage}</FormHelperText>
      ) : null}
    </FormControl>
  ) : (
    <LightStyledInput
      fullWidth={!!fullWidth}
      size="small"
      value={value ? dayjs(value).locale(locale).format("L") : ""}
      label={label}
      placeholder={placeholder}
      readOnly
      name={name}
      disabled={disabled}
    />
  );
};

export default LightStyledDatepicker;
