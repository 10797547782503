import SendIcon from "@mui/icons-material/Send";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid2,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { RedeemSharingTokenFailureFromJSON, ResponseError } from "@syadem/kairos-citizen-js";
import { JSX, useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { formatDateForApi } from "../../../utils/date";
import { dayjs, localizedPlaceholder } from "../../../utils/dayjs";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import StyledDatepicker from "../mui/StyledDatepicker";

export function RedeemSharingTokenForm({ token }: { token: string }): JSX.Element {
  const { t, locale } = useI18n();

  const api = useKairosApi();

  const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);

  const [birthDate, setBirthDate] = useState<Date | null | undefined>(undefined);
  const [birthDateError, setBirthDateError] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [newHealthRecordId, setNewHealthRecordId] = useState<string | undefined>(undefined);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      (async () => {
        e.preventDefault();

        const error_message = [
          "share.errors.error_1",
          "share.errors.error_2",
          "share.errors.error_3",
          "share.errors.error_4",
          "share.errors.error_5",
        ] as const;

        if (!api) {
          return;
        }
        if (!birthDate) {
          setBirthDateError(true);
          return;
        }
        if (!isChecked) {
          setCheckboxError(true);
          return;
        }

        setNewHealthRecordId(undefined);
        setFailureMessage(undefined);
        setIsSending(true);
        try {
          const { healthRecordId } = await api.recordSharingApi.redeemSharingToken({
            birthDate: formatDateForApi(birthDate),
            sharingToken: token,
          });

          setNewHealthRecordId(healthRecordId);
        } catch (error) {
          const response = await buildErrorMessage(error);
          setFailureMessage(t(error_message[response.index], { remaining_attempts: response.value }));
        } finally {
          setIsSending(false);
        }
      })();
    },
    [api, birthDate, isChecked, token, t],
  );
  return (
    <form onSubmit={submitHandler}>
      <Grid2 container rowSpacing={2} columnSpacing={8}>
        {newHealthRecordId ? (
          <Grid2 size={12}>
            {newHealthRecordId ? (
              <Alert data-testid="success-alert" severity="success">
                <AlertTitle>{t("health_records.add_success")}</AlertTitle>
                <Link component={RouterLink} to={`/health-records/${newHealthRecordId}`}>
                  {t("health_records.open_added_hr")}
                </Link>
              </Alert>
            ) : null}
          </Grid2>
        ) : (
          <>
            <Grid2 size={12}>
              <Typography variant="body1" sx={{ pb: 1, pt: 1 }}>
                {t("share.security_msg")}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <StyledDatepicker
                name="birthDateInput"
                placeholder={localizedPlaceholder(locale, t)}
                label={t("common.user_infos.birth_date")}
                testId="birth-date-input"
                maxDate={dayjs()}
                onChange={(date) => {
                  setBirthDate(date && date.toDate());
                  setBirthDateError(!date);
                }}
                value={birthDate ? dayjs(birthDate) : null}
                error={birthDateError}
              />
            </Grid2>
            <Grid2 size={12}>
              <FormControlLabel
                sx={{
                  color: checkboxError ? "error.main" : "text.primary",
                }}
                control={
                  <Checkbox
                    required
                    name="attest"
                    checked={isChecked}
                    data-testid="attest-checkbox"
                    onChange={(_e, checked) => {
                      setIsChecked(checked);
                      setCheckboxError(checked !== true);
                    }}
                  />
                }
                label={t("share.attest")}
              />
            </Grid2>
            <Grid2 size={12}>
              {failureMessage ? (
                <Alert data-testid="error-alert" severity="error" sx={{ marginY: 3 }}>
                  {failureMessage}
                </Alert>
              ) : null}
            </Grid2>
            <Grid2 size={12}>
              <Stack direction="row" justifyContent="end">
                <Button
                  data-testid="submit-button"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  endIcon={isSending ? <CircularProgress color="inherit" size="1em" /> : <SendIcon />}
                  disabled={isSending || newHealthRecordId !== undefined || isChecked !== true || !birthDate}
                  disableElevation
                >
                  {t("health_records.add_this_hr")}
                </Button>
              </Stack>
            </Grid2>
          </>
        )}
      </Grid2>
    </form>
  );
}

async function buildErrorMessage(error: unknown) {
  if (
    (error instanceof Response && error.status === 400) ||
    (error instanceof ResponseError && error.response.status === 400)
  ) {
    const response = error instanceof Response ? error : error.response;
    const body = RedeemSharingTokenFailureFromJSON(await response.json());

    if (body.error.code === "invalid_birth_date" && body.error.remainingAttemptsCount !== undefined) {
      if (body.error.remainingAttemptsCount === 0) {
        return { index: 0, value: body.error.remainingAttemptsCount };
      } else if (body.error.remainingAttemptsCount === 1) {
        return { index: 1, value: "" };
      } else {
        return { index: 2, value: body.error.remainingAttemptsCount };
      }
    } else {
      return { index: 3, value: "" };
    }
  } else {
    return { index: 4, value: "" };
  }
}
