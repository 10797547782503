import { HealthRecord, HealthRecordGenderEnum } from "@syadem/kairos-citizen-js";
import { useNavigate } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { Country } from "../../../domain/country";
import { SyntheticEvent, useMemo, useState } from "react";
import { Dayjs } from "dayjs";
import { dayjs, localizedPlaceholder } from "../../../utils/dayjs";
import Fuse from "fuse.js";
import { formatDateForApi } from "../../../utils/date";
import { Button, Divider, Grid2, MenuItem, Paper } from "@mui/material";
import { theme } from "../../layout/Theme";
import { CapitalizeFirstNames } from "../../../utils/name";
import { CancelButton } from "../../components/mui/StyledButtons";
import { CitiesAutocomplete } from "../../components/CitiesAutocomplete";
import { LightStyledInput } from "../../components/mui/LightStyledInput";
import LightStyledDatepicker from "../../components/mui/LightStyledDatepicker";
import { LightStyledSelect } from "../../components/mui/LightStyledSelect";
import LightStyledAutoComplete from "../../components/mui/LightStyledAutoComplete";

export type HealthRecordFormHandler = (
  body: {
    healthRecord: {
      firstNames: string;
      lastName: string;
      customaryName: string | null;
      birthDate: Date;
      gender: HealthRecordGenderEnum;
      externalId: string | null;
      zipCode: string | null;
      birthCountryCode: string | null;
      birthCityCode: string | null;
    };
  },
  id?: string,
) => void;

export interface HealthRecordFormProps {
  onSubmit?: HealthRecordFormHandler;
  healthRecord?: HealthRecord;
  isReadonly?: boolean;
}

export function HealthRecordForm({ healthRecord, isReadonly, onSubmit }: HealthRecordFormProps) {
  const navigate = useNavigate();
  const { t, locale, getObject } = useI18n();
  const { defaultCountryCode, zipCode, securityNumber } = useCountryConfig();
  
  const countries = useMemo(() => {
    return Object.entries(getObject("countries")).reduce(
      (acc: Country[], [key, value]) => [...acc, { code: key, name: value }],
      [],
    );
  }, [getObject]);

  const defaultBirthCountry = useMemo(() => {
    const countryCode = healthRecord?.birthCountryCode || defaultCountryCode;

    return countries.find((c) => c.code == countryCode) ?? null
  }, [countries, healthRecord, defaultCountryCode])

  const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);
  const [invalidZipCode, setInvalidZipCode] = useState<boolean>(false);
  const [invalidBirthDate, setInvalidBirthDate] = useState<boolean>(false);
  const [firstNames, setFirstNames] = useState<string>(healthRecord?.firstNames ?? "");
  const [lastName, setLastName] = useState<string>(healthRecord?.lastName ?? "");
  const [customaryName, setCustomaryName] = useState<string>(healthRecord?.customaryName ?? "");
  const [gender, setGender] = useState<HealthRecordGenderEnum | undefined>(healthRecord?.gender);
  const [birthDate, setBirthDate] = useState<Dayjs | null>(healthRecord?.birthDate ? dayjs.utc(healthRecord.birthDate) : null);
  const [birthCountry, setBirthCountry] = useState<Country | null>(defaultBirthCountry);
  const [birthCityCode, setBirthCityCode] = useState<string | undefined>(healthRecord?.birthCityCode ?? undefined);
  
  const filterCountries = (options: Country[], { inputValue }: { inputValue: string }) => {
    if (!inputValue) return options;

    const fuse = new Fuse(options, { keys: ["name"], threshold: 0.3 });
    const results = fuse.search(inputValue);

    return results.map((result) => result.item);
  };

  const validateZipCode = (value: string): boolean => {
    if (value.trim() === "") return true;

    return !!value.match(zipCode.regex);
  }

  const validateBirthDate = (value: Dayjs | null): boolean => {
    if (value === null) return true;

    return !value.isAfter(dayjs());
  }

  const submitForm = async (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    if (!form.reportValidity()) return;

    const formParams = Object.fromEntries(formData.entries()) as {
      customaryName: string;
      externalId: string;
      firstNames: string;
      gender: HealthRecordGenderEnum;
      lastName: string;
      zipCode: string;
    };

    if (!securityNumber.validator(formParams.externalId)) return;
    if (!validateZipCode(formParams.zipCode)) return;
    if (!validateBirthDate(birthDate)) return;
    if (!onSubmit) return;

    onSubmit(
      {
        healthRecord: {
          firstNames: formParams.firstNames.trim(),
          lastName: formParams.lastName.trim(),
          customaryName: formParams.customaryName.trim() == "" ? null : formParams.customaryName.trim(),
          birthDate: formatDateForApi((birthDate as Dayjs).toDate()),
          gender: formParams.gender,
          externalId: formParams.externalId.trim() == "" ? null : formParams.externalId.trim(),
          zipCode: formParams.zipCode.trim() == "" ? null : formParams.zipCode.trim(),
          birthCountryCode: birthCountry?.code ?? null,
          birthCityCode: birthCityCode ?? null
        }
      },
      healthRecord?.id
    )
  }

  return (
    <Paper
      sx={{
        overflow: "hidden",
        backgroundColor: "background.paper",
        border: `solid 1px ${theme.palette.neutral[200]}`
      }}
      elevation={0}
    >
      <form onSubmit={submitForm} noValidate autoComplete="off" id="health-record-form">
        <Grid2 container rowSpacing={2} columnSpacing={4} justifyContent="space-between" padding={4}>
          <Grid2 size={12} sx={{ mb: 1 }}>
            <securityNumber.Input
              isReadonly={isReadonly}
              openDatepicker={() => setDatePickerOpened(true)}
              gender={gender}
              setGender={setGender}
              birthDate={birthDate}
              setBirthDate={setBirthDate}
              setBirthCityCode={setBirthCityCode}
              defaultValue={healthRecord?.externalId ?? undefined}
            />
          </Grid2>
          <Grid2 size={12} sx={{ mb: 1 }}>
            <Divider variant="middle" sx={{ opacity: "0.6" }} />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledInput
              required
              fullWidth
              label={t("common.user_infos.first_names")}
              placeholder="ex : Jean"
              readOnly={isReadonly}
              name="firstNames"
              onChange={(e) => setFirstNames(CapitalizeFirstNames(e.target.value))}
              value={firstNames}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledInput
              required
              fullWidth
              label={t("common.user_infos.last_name")}
              placeholder="ex : Dupont"
              readOnly={isReadonly}
              name="lastName"
              onChange={(e) => setLastName(e.target.value.toUpperCase())}
              value={lastName}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledInput
              fullWidth
              label={t("common.user_infos.customary_name")}
              placeholder="ex : Dubois"
              readOnly={isReadonly}
              name="customaryName"
              onChange={(e) => setCustomaryName(e.target.value.toUpperCase())}
              value={customaryName}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledDatepicker
              label={t("common.user_infos.birth_date")}
              placeholder={localizedPlaceholder(locale, t)}
              name="birthDate"
              maxDate={dayjs.utc()}
              readOnly={isReadonly}
              onChange={(value) => {
                setInvalidBirthDate(!validateBirthDate(value));
                setBirthDate(value);
              }}
              open={datePickerOpened}
              value={birthDate}
              onOpen={() => setDatePickerOpened(true)}
              onClose={() => setDatePickerOpened(false)}
              fullWidth
              required
              error={invalidBirthDate}
              errorMessage={invalidBirthDate ? t("common.errors.birthDate") : undefined}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledSelect
              label={t("common.gender.s")}
              placeholder={`ex : ${t("common.gender.m")}`}
              renderValue={(gender) => t(`common.gender.${gender.toLowerCase()}`)}
              fullWidth
              required
              readOnly={isReadonly}
              name="gender"
              value={gender ?? ""}
              onChange={(e) => setGender(e.target.value as HealthRecordGenderEnum)}
            >
              <MenuItem value="M">{t("common.gender.m")}</MenuItem>
              <MenuItem value="F">{t("common.gender.f")}</MenuItem>
            </LightStyledSelect>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledInput
              name="zipCode"
              fullWidth
              size="small"
              label={t("common.user_infos.zip_code")}
              required={zipCode.requiredForHealthRecord}
              placeholder={zipCode.placeholder}
              readOnly={isReadonly}
              defaultValue={healthRecord?.zipCode}
              onChange={(_e) => setInvalidZipCode(false) }
              onBlur={(e) => setInvalidZipCode(!validateZipCode(e.target.value))}
              error={invalidZipCode}
              errorMessage={invalidZipCode ? t("common.errors.zipCode") : undefined}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            <LightStyledAutoComplete<Country>
              name="birthCountryCode"
              label={t("common.user_infos.birth_country")}
              fullWidth
              placeholder="ex : France"
              readOnly={isReadonly}
              options={countries}
              filterOptions={filterCountries}
              getOptionLabel={(country: Country) => country.name || ""}
              value={birthCountry}
              onChange={(_event, value) => {
                setBirthCountry(value);
                setBirthCityCode(undefined);
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 6 }}>
            {birthCountry?.code === defaultCountryCode && (
              <CitiesAutocomplete
                label={t("common.user_infos.birth_place")}
                placeholder={t("common.user_infos.birth_place")}
                readOnly={isReadonly}
                cityCode={birthCityCode}
                onChange={(value) => setBirthCityCode(value?.code)}
              />
            )}
          </Grid2>
          <Grid2 container justifyContent="space-between" size={12} sx={{ marginTop: "30px" }}>
            <CancelButton onClick={() => navigate(-1)} variant="contained">
              {t("common.cta.cancel")}
            </CancelButton>
            {!isReadonly && (
              <Button variant="contained" type="submit" disableElevation>
                {t("common.cta.validate")}
              </Button>
            )}
          </Grid2>
        </Grid2>
      </form>
    </Paper>
  );
}