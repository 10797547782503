import Typography from '@mui/material/Typography';
import { IconButton, Grid2 } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";

function AnimatedSortIcon({
  selected,
  order,
  onClick,
}: {
  selected: boolean;
  order: "asc" | "desc" | undefined;
  onClick: () => void;
}) {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      sx={{
        transform: selected && order === "asc" ? "rotate(180deg)" : "none",
        transition: "transform 0.3s ease-in-out",
        p: 0,
        ml: 1,
      }}
    >
      {selected ? <ArrowDownwardIcon fontSize="small" /> : <SortIcon fontSize="small" />}
    </IconButton>
  );
}

export type SortableColumn = {
  id: string;
  label: string;
  width: number;
  sortable?: boolean;
};

interface SortableListHeaderProps {
  columns: SortableColumn[];
  updateSort: (newOrderBy: string) => void;
  order: "asc" | "desc";
  orderBy: string;
}

function SortableListHeader({
  columns,
  updateSort,
  order,
  orderBy,
}: SortableListHeaderProps) {
  const theme = useTheme();

  return (
    <Grid2
      container
      sx={{
        px: 2,
        py: 2,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
        mb: 0.3,
        display: { xs: "none", md: "flex" },
      }}
    >
      <Grid2 container size={11}>
        {columns.map((column) => (
          <Grid2 key={column.id} container size={column.width} alignItems="center">
            <Typography variant="subtitle2" fontWeight={600}>
              {column.label}
            </Typography>
            {column.sortable !== false && (
              <AnimatedSortIcon
                selected={orderBy === column.id}
                order={order}
                onClick={() => updateSort(column.id)}
              />
            )}
          </Grid2>
        ))}
      </Grid2>
      <Grid2 flex={1} />
    </Grid2>
  );
}

export default SortableListHeader;
