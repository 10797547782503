import { UserManager } from "oidc-client-ts";
import { AppStore } from "../store";
import { Service, ServicePayload } from "./service";
import { SignInService } from "./signInService";
import { SignOutService } from "./signOutService";
import { EditUserService } from "./editUserService";
import { AppRouter } from "../appConfig";
import { ShareHealthRecordsService } from "./ShareHealthRecordsService";
import { Apis } from "../network/apis";
import { CreateDocumentAndAttachFilesService } from "./documents/CreateDocumentAndAttachFilesService";
import { DeleteDocumentService } from "./documents/DeleteDocumentService";
import { DownloadDocumentFileService } from "./documents/DownloadDocumentFileService";
import { UpdateDocumentService } from "./documents/UpdateDocumentService";

type ServiceCollection = {
  // We don't care about the return type of the service here
  [key: string]: Service<ServicePayload, any>;
};

export interface Services extends ServiceCollection {
  signIn: SignInService;
  signOut: SignOutService;
  editUser: EditUserService;
  shareHealthRecords: ShareHealthRecordsService
}

interface ServiceDependencies {
  store: AppStore;
  oidcManager: UserManager;
  router: AppRouter;
  apis: Apis;
}

export function initServices({ store, oidcManager, router, apis }: ServiceDependencies): Services {
  return {
    signIn: new SignInService(store, window, oidcManager, router),
    signOut: new SignOutService(window, oidcManager),
    editUser: new EditUserService(window, oidcManager),
    shareHealthRecords: new ShareHealthRecordsService(apis, router),
    createDocumentAndAttachFiles: new CreateDocumentAndAttachFilesService(apis),
    deleteDocument: new DeleteDocumentService(apis),
    updateDocument: new UpdateDocumentService(apis),
    downloadDocumentFile: new DownloadDocumentFileService(apis),
  };
}
