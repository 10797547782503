import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ReactNode } from "react";
import { theme } from "../../layout/Theme";
import { LightBootstrapInput, LightStyledInput } from "./LightStyledInput";

export const LightStyledSelect = ({
  label,
  name,
  fullWidth,
  error,
  errorMessage,
  disabled,
  value,
  required,
  placeholder,
  readOnly,
  children,
  onChange,
  onOpen,
  renderValue,
}: {
  label?: string;
  name: string;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value?: string;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  children?: React.ReactNode;
  onChange?: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined;
  onOpen?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined;
  renderValue?: (value: string) => string;
}) => {
  return !readOnly ? (
    <FormControl variant="standard" error={error} fullWidth={!!fullWidth} disabled={disabled} required={required}>
      {label ? (
        <InputLabel
          shrink
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            }
          }}
        >
          {label}
        </InputLabel>
      ) : null}
      <Select
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        displayEmpty
        renderValue={(selected) => {
          if (!selected || selected.length === 0) {
            return (
              <Typography color="rgba(0,0,0,0.87)" fontSize={14} sx={{ opacity: "0.42" }}>
                {placeholder}
              </Typography>
            );
          }
          return renderValue ? renderValue(selected) : selected;
        }}
        input={<LightBootstrapInput value={value} name={name} readOnly={readOnly} />}
        sx={{
          "&.MuiOutlinedInput-root": {
            "&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary[400],
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary[500],
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.neutral[200],
            }
          }
        }}
      >
        {children}
      </Select>
      {error && errorMessage ? (
        <FormHelperText sx={{ ml: 0 }}>{errorMessage}</FormHelperText>
      ) : null}
    </FormControl>
  ) : (
    <LightStyledInput
      fullWidth={!!fullWidth}
      size="small"
      value={renderValue && value ? renderValue(value) : ""}
      label={label}
      placeholder={placeholder}
      readOnly
      required={required}
      name={name}
      disabled={disabled}
    />
  );
};
