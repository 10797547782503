import React, { useCallback, useState, useEffect } from "react";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import {
  Box,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Stack,
  Button,
  useTheme,
  alpha,
  LinearProgress,
  Fade,
  Badge,
} from "@mui/material";
import { useI18n } from "../../../hooks/useI18n";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const MAX_TOTAL_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const ALLOWED_EXTENSIONS = ["pdf", "png", "jpeg", "jpg", "tiff"];
const IMAGE_EXTENSIONS = ["png", "jpeg", "jpg", "tiff"];

interface FileValidation {
  isValid?: boolean;
  errorMessage?: string;
}

type FileWithValidation = File & FileValidation;

const isDuplicate = (file: File, existingFiles: File[]): boolean => {
  return existingFiles.some(
    (existingFile) =>
      existingFile.name === file.name &&
      existingFile.size === file.size &&
      existingFile.lastModified === file.lastModified,
  );
}
interface FileUploadZoneProps {
  files: FileWithValidation[];
  setFiles: (files: Array<FileWithValidation>) => void;
  fileError: string;
  setFileError: (error: string) => void;
  loading?: boolean;
}

export function FileUploadZone({ files, setFiles, fileError, setFileError, loading = false }: FileUploadZoneProps) {
  const { t } = useI18n();
  const theme = useTheme();
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > MAX_TOTAL_SIZE) {
      setFileError(t("documents.file_upload.total_size_error", { size: "10MB" }));
    } else {
      const hasInvalidIndividual = files.some((file) => (file as any).isValid === false);
      if (hasInvalidIndividual) {
        setFileError(t("documents.file_upload.invalid_files"));
      } else {
        setFileError("");
      }
    }
  }, [files, setFileError, t]);

  const addFilesWithoutDuplicates = useCallback(
    (newFiles: File[]) => {
      const uniqueNewFiles = newFiles.filter((file) => !isDuplicate(file, files));
      if (uniqueNewFiles.length > 0) {
        setFiles([...files, ...uniqueNewFiles]);
      }
    },
    [files, setFiles],
  );

  const getFileIcon = useCallback(
    (fileName: string) => {
      const extension = fileName.split(".").pop()?.toLowerCase() || "";

      if (extension === "pdf") {
        return <DescriptionIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />;
      } else if (IMAGE_EXTENSIONS.includes(extension)) {
        return <ImageIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />;
      } else {
        return <InsertDriveFileIcon fontSize="small" sx={{ color: theme.palette.grey[600] }} />;
      }
    },
    [theme],
  );

  const validateFiles = useCallback(
    (filesToValidate: File[]): File[] => {
      const validatedFiles: File[] = [];
      let hasInvalidFiles = false;

      for (const file of filesToValidate) {
        const fileWithValidation = file as FileWithValidation;
        const extension = file.name.split(".").pop()?.toLowerCase() || "";

        if (!ALLOWED_EXTENSIONS.includes(extension)) {
          fileWithValidation.isValid = false;
          fileWithValidation.errorMessage = t("documents.file_upload.file_type_error");
          hasInvalidFiles = true;
        } else if (file.size > MAX_FILE_SIZE) {
          fileWithValidation.isValid = false;
          fileWithValidation.errorMessage = t("documents.file_upload.file_size_error", { size: "10MB" });
          hasInvalidFiles = true;
        } else {
          fileWithValidation.isValid = true;
        }

        validatedFiles.push(fileWithValidation);
      }

      if (hasInvalidFiles) {
        setFileError(t("documents.file_upload.invalid_files"));
      } else {
        setFileError("");
      }

      return validatedFiles;
    },
    [setFileError, t],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;
      if (fileList) {
        const filesArray = Array.from(fileList);
        const validatedFiles = validateFiles(filesArray);
        addFilesWithoutDuplicates(validatedFiles);
      }

      // Reset the input value so the same file can be selected again if needed
      event.target.value = "";
    },
    [validateFiles, addFilesWithoutDuplicates],
  );

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) {
        setIsDragging(true);
      }
    },
    [isDragging],
  );

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const droppedFiles = Array.from(e.dataTransfer.files);
      const validatedFiles = validateFiles(droppedFiles);
      addFilesWithoutDuplicates(validatedFiles);
    },
    [validateFiles, addFilesWithoutDuplicates],
  );

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = files.filter((_, i) => i !== index);
      setFiles(newFiles);

      const stillHasInvalidFiles = newFiles.some((file) => (file as FileWithValidation).isValid === false);
      if (!stillHasInvalidFiles) {
        setFileError("");
      }

      if (newFiles.length === 0) {
        setFileError("");
      }
    },
    [files, setFiles, setFileError],
  );

  const getFileSize = (size: number): string => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  };

  const openFileSelector = (e: React.MouseEvent) => {
    e.stopPropagation();
    document.getElementById("file-input")?.click();
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        p: files.length > 0 ? 2 : 3,
        border: `2px dashed ${isDragging
          ? alpha(theme.palette.primary.main, 0.7)
          : fileError
            ? alpha(theme.palette.error.main, 0.3)
            : theme.palette.divider
          }`,
        borderRadius: 2,
        textAlign: "center",
        cursor: files.length === 0 ? "pointer" : "default",
        backgroundColor: isDragging
          ? alpha(theme.palette.primary.light, 0.1)
          : fileError
            ? alpha(theme.palette.error.light, 0.05)
            : undefined,
        transition: theme.transitions.create(["background-color", "border"]),
        "&:hover": {
          backgroundColor: files.length === 0 ? alpha(theme.palette.primary.light, 0.05) : undefined,
          border: `2px dashed ${files.length === 0
            ? alpha(theme.palette.primary.main, 0.5)
            : fileError
              ? alpha(theme.palette.error.main, 0.3)
              : theme.palette.divider
            }`,
        },
        position: "relative",
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={(e) => {
        // Only open file selector if we have no files and user clicks on the drop zone
        if (files.length === 0) {
          openFileSelector(e);
        }
      }}
    >
      <input
        accept=".pdf,.png,.jpeg,.jpg,.tiff"
        style={{ display: "none" }}
        id="file-input"
        type="file"
        multiple
        onChange={handleFileChange}
        disabled={loading}
      />

      {files.length === 0 ? (
        <Box
          sx={{
            py: 3,
            px: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 180,
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: isDragging
                ? alpha(theme.palette.primary.light, 0.1)
                : alpha(theme.palette.grey[50], 0.9),
              p: 1.5,
              mb: 1,
              transition: theme.transitions.create(["background-color", "transform"]),
              transform: isDragging ? "scale(1.1)" : "scale(1)",
            }}
          >
            {isDragging ? (
              <CloudDownloadOutlined
                sx={{
                  fontSize: 40,
                  color: theme.palette.primary.main,
                  transition: theme.transitions.create(["color", "transform"]),
                  animation: isDragging ? "pulse 1.5s infinite" : "none",
                  "@keyframes pulse": {
                    "0%": { opacity: 0.7 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.7 },
                  },
                }}
              />
            ) : (
              <CloudUploadOutlined
                sx={{
                  fontSize: 40,
                  color: theme.palette.grey[600],
                  transition: theme.transitions.create(["color"]),
                }}
              />
            )}
          </Box>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 1.5,
              maxWidth: 400,
              textAlign: "center",
            }}
          >
            {t("documents.file_upload.drag_drop_or_browse")}
          </Typography>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<CloudUploadOutlined fontSize="small" />}
            onClick={openFileSelector}
            sx={{ mb: 2, fontWeight: 500 }}
          >
            {t("documents.file_upload.add_files")}
          </Button>

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {t("documents.file_upload.file_types_allowed_info", { size: "10MB" })}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ p: 2 }}
          onClick={(e) => e.stopPropagation()} // Prevent click from bubbling to parent
        >
          <Box sx={{ position: "relative" }}>
            <Paper
              variant="outlined"
              sx={{
                maxHeight: 250,
                overflow: "auto",
                p: 0,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.05)",
                opacity: loading ? 0.7 : 1,
              }}
            >
              {files.map((file, index) => {
                const fileIsInvalid = (file as FileWithValidation).isValid === false;

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1.5,
                      borderBottom: index < files.length - 1 ? `1px solid ${theme.palette.divider}` : "none",
                      backgroundColor: fileIsInvalid ? alpha(theme.palette.error.light, 0.07) : undefined,
                      "&:hover": {
                        backgroundColor: fileIsInvalid
                          ? alpha(theme.palette.error.light, 0.12)
                          : alpha(theme.palette.primary.light, 0.05),
                      },
                      transition: theme.transitions.create(["background-color"]),
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden", flex: 1 }}>
                      <Box sx={{ mr: 1.5, display: "flex", alignItems: "center" }}>
                        {fileIsInvalid ? (
                          <Badge
                            overlap="circular"
                            badgeContent={
                              <ErrorOutlineIcon
                                fontSize="small"
                                sx={{ fontSize: 14, color: theme.palette.error.light }}
                              />
                            }
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            {getFileIcon(file.name)}
                          </Badge>
                        ) : (
                          getFileIcon(file.name)
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          overflow: "hidden",
                          flex: 1,
                        }}
                      >
                        <Tooltip title={file.name}>
                          <Typography
                            variant="body2"
                            sx={{
                              textAlign: "left",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "100%",
                              fontWeight: 500,
                              color: fileIsInvalid ? theme.palette.error.light : undefined,
                            }}
                          >
                            {file.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                          <Typography variant="caption" color="text.secondary">
                            {getFileSize(file.size)}
                          </Typography>
                          {fileIsInvalid && (
                            <Tooltip title={(file as FileWithValidation).errorMessage || ""}>
                              <Typography
                                variant="caption"
                                sx={{
                                  ml: 1,
                                  color: theme.palette.error.light,
                                  fontWeight: 500,
                                }}
                              >
                                {(file as FileWithValidation).errorMessage}
                              </Typography>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Tooltip title={t("common.cta.delete")}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFile(index);
                        }}
                        disabled={loading}
                        sx={{
                          color: theme.palette.text.secondary,
                          opacity: 0.7,
                          "&:hover": {
                            opacity: 1,
                            backgroundColor: alpha(theme.palette.error.light, 0.15),
                            color: theme.palette.error.main,
                          },
                        }}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}
            </Paper>

            {loading && (
              <Fade in={loading}>
                <LinearProgress
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 3,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                />
              </Fade>
            )}
          </Box>

          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ mt: 2, px: 0.5 }}>
            <Typography variant="caption" color="text.secondary">
              {t("documents.file_upload.files_summary", { amount: files.length, totalSize: getFileSize(files.reduce((sum, file) => sum + file.size, 0)) })}
            </Typography>

            <Button
              variant="text"
              size="small"
              startIcon={<CloudUploadOutlined fontSize="small" />}
              color="primary"
              disabled={loading}
              sx={{
                textTransform: "none",
                fontWeight: 500,
              }}
              onClick={openFileSelector}
            >
              {t("documents.file_upload.add_more_files")}
            </Button>
          </Stack>
        </Box>
      )}

      {fileError && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.75,
            backgroundColor: alpha(theme.palette.error.light, 0.1),
            borderRadius: 1,
            py: 0.75,
            px: 1.5,
            border: `1px solid ${alpha(theme.palette.error.main, 0.2)}`,
          }}
        >
          <ErrorOutlineIcon fontSize="small" sx={{ color: theme.palette.error.light, fontSize: "0.9rem" }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: theme.palette.error.main,
              fontSize: "0.825rem",
            }}
          >
            {fileError}
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
