import { captureException } from "@sentry/react";
import { Vaccine } from "@syadem/daphne-js";
import { Result } from "@zxing/library";
import Polyglot from "node-polyglot";
import { I18nKey } from "../ui/providers/I18nContext";
import { dayjs } from "./dayjs";
export interface ParsedDatamatrixRaw {
  gtin?: string;
  productionDate?: string;
  bestBeforeDate?: string;
  expirationDate?: string;
  batchNumber?: string;
  serialNumber?: string;
  preventionMethodId?: string;
  portugeseId?: string;
}

export interface ParsedDatamatrix
  extends Omit<ParsedDatamatrixRaw, "productionDate" | "bestBeforeDate" | "expirationDate"> {
  productionDate?: Date;
  bestBeforeDate?: Date;
  expirationDate?: Date;
}

export interface ScanResult {
  parsedDatamatrix: ParsedDatamatrix;
  vaccine?: Vaccine;
}

const maybeConvertToDate = (rawDate?: string): Date | undefined => {
  if (rawDate && rawDate.length === 6) {
    /* rawDate = "YYMMDD" */
    const day = rawDate.slice(4);
    const date = dayjs.utc(rawDate, "YYMMDD");

    if (day === "00") {
      return date.endOf("month").toDate();
    }
    return date.toDate();
  }
};

const processDatamatrixDates = (parsedDatamatrixRaw: ParsedDatamatrixRaw): ParsedDatamatrix => {
  type DateKey = keyof Pick<ParsedDatamatrixRaw, "productionDate" | "bestBeforeDate" | "expirationDate">;
  const dateKeys: DateKey[] = ["productionDate", "bestBeforeDate", "expirationDate"];

  const parsedDatamatrix = {
    ...parsedDatamatrixRaw,
    productionDate: undefined,
    bestBeforeDate: undefined,
    expirationDate: undefined,
  };

  return dateKeys.reduce((outputObject: ParsedDatamatrix, key: DateKey) => {
    if (parsedDatamatrixRaw[key]) {
      outputObject[key] = maybeConvertToDate(parsedDatamatrixRaw[key]);
    }
    return outputObject;
  }, parsedDatamatrix);
};

const parseDatamatrixStringWithFixedRegexp = (datamatrixString: string): ParsedDatamatrixRaw | undefined => {
  const parsingRegexes = [
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:710)(?<nhrn>\d+)(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:21)(?<serialNumber>\w{1,20})/g,
    /(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:714)(?<portugeseId>\d{7})/g,
  ];

  const applyRegexps = (datamatrixString: string, regexps: RegExp[], currentIndex = 0): RegExpExecArray | null => {
    // Exit if we're out of regexps
    if (!regexps[currentIndex]) {
      return null;
    }

    const match = regexps[currentIndex].exec(datamatrixString);
    const isAFullMatch = match && match[0] === datamatrixString;

    if (isAFullMatch) {
      // We found a regexp fully matching our string!
      return match;
    } else {
      // Let's try the next regexp
      return applyRegexps(datamatrixString, regexps, currentIndex + 1);
    }
  };

  return applyRegexps(datamatrixString, parsingRegexes)?.groups;
};

const parseDatamatrixStringWithDynamicRegexp = (datamatrixString: string) => {
  const regEx =
    /(?:01)(?<gtin>\d{14})|(?:11)(?<productionDate>\d{6})|(?:15)(?<bestBeforeDate>\d{6})|(?:17)(?<expirationDate>\d{6})|(?:10)(?<batchNumber>[a-zA-Z0-9-]{1,20})|(?:714)(?<portugeseId>\d{7})|(?:21)(?<serialNumber>[a-zA-Z0-9-]{1,20})/g;
  const matches = datamatrixString.matchAll(regEx);
  let data: ParsedDatamatrixRaw = {};

  for (const match of matches) {
    const currentGroups = match.groups || {};
    Object.keys(currentGroups).forEach((key) => currentGroups[key] === undefined && delete currentGroups[key]);
    data = { ...data, ...currentGroups };
  }

  if (Object.keys(data).length > 0) {
    return data;
  }
};

export const parseDatamatrixString = (datamatrixString: string) => {
  const regExContainsGtin = /(?:01)(?<gtin>\d{14})/g;
  const matchesWithGtin = [...datamatrixString.matchAll(regExContainsGtin)];

  //The datamatrixString should contain, at least, a gtin
  if (matchesWithGtin.length > 0) {
    const maybeParsedWithFixedRegexp = parseDatamatrixStringWithFixedRegexp(datamatrixString);

    if (maybeParsedWithFixedRegexp) {
      return processDatamatrixDates(maybeParsedWithFixedRegexp);
    }

    const maybeParsedWithDynamicRegexp = parseDatamatrixStringWithDynamicRegexp(datamatrixString);

    if (maybeParsedWithDynamicRegexp) {
      captureException(new Error("Cannot parse datamatrix string with Fixed Regexp"), {
        extra: {
          type: "fixed",
          string: datamatrixString,
        },
      });

      return processDatamatrixDates(maybeParsedWithDynamicRegexp);
    }

    /*
      If we're still here, parsing failed, report error to sentry
    */
    captureException(new Error("Cannot parse datamatrix string"), {
      extra: {
        type: "dynamic",
        string: datamatrixString,
      },
    });
  }
};

export const processDatamatrixCode = async (
  result: Result | string,
  findByDataMatrix: (gtin: string) => Vaccine,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  t: (phrase: I18nKey, options?: number | Polyglot.InterpolationOptions | undefined) => string,
): Promise<ScanResult> => {
  setIsLoading(true);

  const datamatrixString = result.toString();
  const parsedDatamatrix = parseDatamatrixString(datamatrixString);
  if (parsedDatamatrix) {
    if (!parsedDatamatrix.gtin) {
      return { parsedDatamatrix };
    }

    //Look for a vaccine with GTIN14
    let resultDatamatrix = await findByDataMatrix(parsedDatamatrix.gtin);

    if (!resultDatamatrix?.id && parsedDatamatrix.gtin.startsWith("03400")) {
      //Search for a vaccine with CIP-13
      //To remain compatible with the requirements resulting from medicament verification, CIP-13 numbers
      //are embedded into the following NTIN product number structure.CIP-13 numbers are already in in use today.
      // ex: GTIN 03400937713016
      // 0 : Indicator, used with GTIN-14 or „0“ as padding character
      // 3400 : CIP prefix
      // 9 : Constant (9 indicates human medicaments)
      // 3771301: CIP/UCD Code
      // 6: GTIN check digit
      //
      //So if GTIN start with 03400 we have to remove the first 0 to search for CIP
      const res = await findByDataMatrix(parsedDatamatrix.gtin.substring(1));
      if (res.id) {
        resultDatamatrix = res;
      }
    }
    setIsLoading(false);

    if (resultDatamatrix?.id) {
      return {
        parsedDatamatrix,
        vaccine: resultDatamatrix,
      };
    } else {
      return { parsedDatamatrix };
    }
  } else {
    setIsLoading(false);
    throw new Error(t("datamatrix.scanError"));
  }
};
