import {
  CreateDocumentBodyDocument,
  CreateDocumentBodyDocumentCategoryEnum,
  Document,
} from "@syadem/kairos-citizen-js";
import { Service } from "../service";
import { Apis } from "../../network/apis";
import { ActionResult } from "../../actionResult";

interface Payload {
  document: {
    name: string;
    category: CreateDocumentBodyDocumentCategoryEnum;
    date?: Date;
  };
  healthRecordId: string;
  attachments: File[];
  onProgressUpdate?: (progress: number) => void;
}

export class CreateDocumentAndAttachFilesService implements Service<Payload, Document> {
  constructor(
    private readonly apis: Apis
  ) { }

  async call({
    document,
    healthRecordId,
    attachments,
    onProgressUpdate,
  }: Payload): Promise<ActionResult<Document>> {
    let apiDocument = await this.apis.documentApi.createDocument(healthRecordId, {
      document: document as CreateDocumentBodyDocument,
    });

    if (attachments && attachments.length > 0) {
      apiDocument = await this.apis.documentFileApi.attachFiles(
        healthRecordId,
        apiDocument.id,
        attachments,
        onProgressUpdate,
      );
    }

    return {
      type: "success",
      value: apiDocument,
    };
  }
}