import Polyglot from "node-polyglot";
import { I18nInterface } from "../ui/providers/I18nContext";
import { dayjs } from "./dayjs";

type AgeUnit = "common.dates.day" | "common.dates.week" | "common.dates.month" | "common.dates.year";

const getAgeString = (
  age: {
    value: number[];
    unit: AgeUnit[];
  },
  t: I18nInterface["t"],
): string => {
  return age.value.length > 1
    ? `${age.value[0]} ${t(age.unit[0], { smart_count: age.value[0] })} ${t("common.and")} ${age.value[1]} ${t(
        age.unit[1],
        { smart_count: age.value[1] },
      )}`
    : `${age.value[0]} ${t(age.unit[0], { smart_count: age.value[0] })}`;
};

export function utcToday(date: Date = new Date()) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export const calculateAge = (
  end: Date,
  begin: Date,
  withPrecision: boolean,
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string,
): string => {
  const daysDiff = dayjs(end).diff(dayjs(begin), "day");
  const weeksDiff = dayjs(end).diff(dayjs(begin), "week");
  const monthsDiff = dayjs(end).diff(dayjs(begin), "month");
  const yearsDiff = dayjs(end).diff(dayjs(begin), "year");

  if (daysDiff <= 28) {
    return getAgeString({ value: [daysDiff], unit: ["common.dates.day"] }, t);
  } else if (weeksDiff <= 7) {
    return getAgeString({ value: [weeksDiff], unit: ["common.dates.week"] }, t);
  } else if (monthsDiff <= 23) {
    const diffWeek = Math.floor((daysDiff - monthsDiff * 30.5) / 7);

    if (withPrecision && diffWeek > 0) {
      return getAgeString({ value: [monthsDiff, diffWeek], unit: ["common.dates.month", "common.dates.week"] }, t);
    } else {
      return getAgeString({ value: [monthsDiff], unit: ["common.dates.month"] }, t);
    }
  } else {
    const diffMonth = monthsDiff - yearsDiff * 12;

    if (withPrecision && diffMonth > 0) {
      return getAgeString({ value: [yearsDiff, diffMonth], unit: ["common.dates.year", "common.dates.month"] }, t);
    } else {
      return getAgeString({ value: [yearsDiff], unit: ["common.dates.year"] }, t);
    }
  }
};
